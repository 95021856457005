import { useNavigate } from "react-router";
import AnimalzLogo from "../assets/AnimalzAtCFz_Logo_Vertical_White.svg";

export default function Hero() {
  const navigate = useNavigate();
  return (
    <div className="bg-black bg-animalz-background bg-center min-h-svh">
      <div className="container p-8 mx-auto xl:px-0 flex flex-wrap">
        <div className="flex items-center justify-center w-full">
          <div className="max-w-2xl mb-8">
            <h1 className="text-4xl font-bold leading-snug tracking-tight text-white lg:text-4xl lg:leading-tight xl:text-6xl xl:leading-tight">
              <img src={AnimalzLogo} />
            </h1>
            <p className="py-5 text-xl leading-normal text-white lg:text-xl xl:text-2xl text-center">
              {/* Blurb about Animalz at Confuzzled. Sunday night only, 5 DJs */}
              Club Animalz is a fun, carefree, loving club night for
pups, furries, and anyone who is a bit of an animal.
Whether a fluffy fox, a rubbery dog, leathery gator,
or just what you feel comfortable in. So come and be
your wild, unashamedly exciting true self.

            </p>
          </div>
        </div>
        {/* <div className="flex items-center justify-center w-full lg:w-1/2">

        </div> */}
        <div className="flex items-center justify-center w-full">
        <button
            className="rounded border border-brok-purple-500 bg-white px-10 py-4 font-semibold text-brok-purple-600 hover:border-transparent hover:bg-brok-purple-600 hover:text-white hover:-translate-y-1 transition-transform duration-300 ease-in-out"
            onClick={() => {
              navigate("/signup");
            }}
          >
            Signup in Advance!
          </button>
        </div>
      </div>
    </div>
  );
}
