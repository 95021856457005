import Logo from "./Logo";
export default function Navbar() {
  return (
    <>
      <nav className="container mx-auto flex justify-center p-4 xl:px-0">
        <a href="/">
          <Logo className="w-48" />
        </a>
      </nav>
    </>
  );
}
