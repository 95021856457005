import LogoImage from '../assets/cfzlogo_horizontal_white.svg'

interface LogoProps {
  className?: string;
}

export default function Logo({className}: LogoProps) {
  return (
    <div className={className}>
      <img src={LogoImage} alt="Logo" draggable={false} />
    </div>
  )
}