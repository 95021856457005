import classNames from "classnames";

interface SectionCardProps {
  icon?: React.ReactNode;
  title: string;
  children: React.ReactNode;
  className?: string;
}

export default function SectionCard({ icon, title, children, className }: SectionCardProps) {
  return (
    <>
      <div
        className={classNames(
          className,
          "relative h-[6rem] w-full overflow-hidden rounded-tl-[3rem] rounded-tr-md bg-black bg-opacity-50 lg:h-[8.2rem] lg:rounded-tl-[4rem]",
        )}
      >
        <div className="absolute left-0 top-0 flex h-[6rem] w-[6rem] items-center justify-center rounded-full border-[6px] border-white bg-white lg:h-[8.2rem] lg:w-[8.2rem] lg:border-8">
          {icon}
        </div>
        <div className="box-border flex h-[6rem] w-full items-center  border-t-[6px] border-t-animalz-pink p-0 pl-[6rem] lg:h-[8.2rem] lg:border-t-8 lg:pl-[8.2rem]">
          <div className="flex items-center ">
            <span className="p-4 text-2xl font-bold italic text-white lg:text-4xl">{title}</span>
          </div>
        </div>
      </div>
      <div className="h-auto rounded-b-md bg-black bg-opacity-50 px-12 py-4 font-normal text-white">{children}</div>
    </>
  );
}
